/* Instead of .App {} you could do * { } */ 
.App {
	text-align: center;


  /* www.w3schools.com: The width and height properties (and min/max properties) includes content, padding and border.
   * Default 'content-box' would not include padding and border. */
  box-sizing: border-box;
  margin: 0;	/* www.w3schools.com: Margins are used to create space around elements, outside of any defined border.	 */
  padding: 0;
  /* See index.html in public folder regarding importing font from google. Also fontawesome. */
  font-family: 'Roboto', sans-serif;



}


.App_top {
}


.App_clear {
	height: 250px;
	clear: both;
	position: fixed;
}


.App_body {

}








.DoIntegral {
	display: flex;
	width: 50%;
	height: 200px;
	margin: auto;
	border-radius: 10px;
	border: 3px dashed #1c87c9;
	align-items: center;
	justify-content: center;

}



/* ------------------------------------------------------------------------- */

.font_Lora {
  font-family: 'Lora', serif;
}
.font_Roboto {
  font-family: 'Roboto', sans-serif;
}
.font_Lato {
  font-family: 'Lato', sans-serif;
}

/* ------------------------------------------------------------------------- */


  /* https://css-tricks.com/simple-little-use-case-vmin/
   * Viewport units are things like 10vw (10% of the viewport width) or 2vh (2% of the viewport height).
   * Useful sometimes, as they are always relative to the viewport. No cascading or influence by parent elements.
   * There is also vmin, which is the lesser of vw and vh, and vmax, which is the greater.
   * These values can be used for anything that takes a length value, just like px or em or % or whatever. */

/*
.App-logo {
  height: 40vmin;
  width: 80vmin;
  pointer-events: none;
}
*/

/* Having this in a css comment to avoid motion:
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  /* background-color:
   * #282c34; */
  /* Dark blue:
   * background-color: #616883; */
  /* Blue gray:
   * background-color: #171b32; */
  /* Orange:
   * background-color: #f15a29; */
  /* White:
   * background-color: #ffffff; */

  background-color: #ffffff;
  /* https://cssreference.io/property/min-height/
   * If the minimum height is larger than the element's actual height, the min height will be applied. */
  min-height: 100vh;
  display: flex;
  flex-direction: column;	/* Top to bottom.	https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
  align-items: center;		/* This defines the default behavior for how flex items are laid out along the cross axis on the current line. */
  justify-content: center;	/* This defines the alignment along the main axis. */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
