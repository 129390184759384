/* Color #fff is white rgb(255,255,255) or rgba(255,255,255,1.00). */

/* www.bitdegree.org/learn/css-translate CSS translate moves an element up and down or side-to-side.
 By indicating one value, you move the element to the right side. Negative values move elements to the left.
 The second value moves the element down. Negative baluve move element up. */

/*
.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
}
@media screen and (max-width: 960px) {
	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(25%,50%);	
	}
}
.fa-react {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}
*/

/* www.w3schools.com/html/html_colors_rgb.asp
 *
 * In HTML, a color can be specified as an RGB value, using this formula: rgb(red,green,blue).
 * Each parameter (red,green, and blue) defines the intensity of the color with a value between 0 and 255.
 * This means that there are 256*256*256=16777216 possible colors.
 * For example, rbg(255,0,0) is displayed as red, because red is set to its highest value (255), and the other two are set to 0.
 * To display black, rgb(0,0,0). To display white, rgb(255,255,255).
 * RGBA color values are an extension of RGB color values with an Alpha channel - which specifies the opacity of a color.
 * An RGBA color value is specified with rgba(red,green,blue,alpha).
 * The alpha parameter is a number between 0.0 (fully transparent) and 1.0 (not transparent at all).
 *
 * developer.mozilla.org/en-US/docs/docs/Web/CSS/linear-gradient()
 *
 * By adding more color-stop points on the gradient line, you can create a highly customized transition between multiple colors.
 * A color-stop's position can be explicitly defined by using a <length> or a <percentage>.
 * If you don't specify the location of a color, it is placed halfway between the one that precedes it and the one that follows it.
 * The following two gradients are equivalent.
 * 	linear-gradient(red,orange,yellow,green,blue);
 * 	linear-gradient(red 0%,orange 25%,yellow 50%,green 75%,blue 100%); */

/* www.w3schools.com/cssref/pr_dim_height.asp
 * The height property sets the height of an element.
 * The height of an element does not include padding, borders, or margins.
 * If height: auto; (default value) the element will automatically adjust its height to allow its content to be displayed correctly.
 * If height is set to a numeric value (like pixels, (r)em, percentages) then if the content does not fit within the specified height,
 * 	if will overflow. How the container will handle the overflowing is defined by the overflow property. Default is 'visible' */

/* www.sitepoint.com/understanding-and-using-rem-units-in-css
 * One rem ("root em") is equal to the computed value of font-size on the root element. */

/* https://www.w3schools.com/cssref/tryit.asp?filename=trycss_position2
Static position element (default) will remain in the natural flow of the page. It will NOT act as anchor point for an absolute positioned child element.
Relative position element will remain in the natural flow of the page. It will also act as anchor point for an absolute positioned child element.
Absolute position element will not remain in the natural flow of the page. It will position itself according to the closest positioned ancestor. It will also act as anchor point for an absolute positioned child element.
Fixed position element will not remain in the natural flow of the page. It will position itself according to the viewport. It will also act as anchor point for an absolutely positioned  child element.
*/

.NavbarItems {

	/* background: linear-gradient(90deg,rgb(110,94,254) 0%, rgba(73,63,252,1) 100%); */

	background-color: #ffffff;

	/* background-color: #f15a29; */

	/* background-color: #616883; */


	/* 20220127 two lines: */
	position: fixed;
	left: 0.5rem;




	height: 80px;
	display: flex;
	justify-content: center; 
	align-items: center; 
	font-size: 1.2rem;
}
@media screen and (max-width: 960px) {		
	.NavbarItems {
		position: relative;
	}
}

.Nav-logo{
	justify-content: initial;
	/* max-height: 80px; */
}

.Nav-logo-in{
/*	height: 10vmin; */
/*	width: 20vmin; */
	height: 80px;
	width: 240px;

	pointer-events: none;
}

.menu-icon {
	display: none;		/* No display unless screen max width. */
}
@media screen and (max-width: 960px) {		/* www.w3schools.com The @media rule is used in media queries to apply different styles for different media types/devices. */
	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
	}
}
.fa-bars {
	/* color: #fff; */
	color: #171b32;
}
.fa-times {
	/* color: #fff; */
	color: #171b32;
	font-size: 2rem;
}

/* ------------------------------------------------------------------------------------------------------------------------ */

.nav-menu {
	display: grid;
	grid-template-columns: repeat(4, auto);		/* www.3schools.com Specifies the number ( and the widths ) of columns in a grid layout.
								developer.mozilla.org The repeat() CSS function represents a repeated fragment of the track list, allowing
								a large number of columns or rows that exhibit a recurring pattern to be written in a more compact form. */
	grid-gap: 0px;					/* www.w3schools.com The grid-gap property defines the size of the gap between the rows and columns in a grid payout. */
	list-style: none;				/* No bulletts. */
	text-align: center;
	width: 70vw;					/* 70% of the height of the viewport. */
	/* justify-content: end; */

	justify-content: end;


	/* margin-right: 2rem;  */
}

@media screen and (max-width: 960px) {		
	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 400px;
		position: absolute;		/* www.tutorials.com/absolute-positioning-using-css Renders the element relative to the first positioned (except static) parent. */
		top: 80px;			/* www.w3schools.com The top property affects the vertical positioned element. */
		left: -100%;			/* www.w3schools.com The left property affects the horizontal position of a positioned element. */

		/* left: -100%; */

		opacity: 1;			/* www.w3schools.com Opacity level 1 is not transparent at all. */
		transition: all 0.5s ease;


		justify-content: center;
	}

	.nav-menu.active {
		/* background: #6668f4; */
		/* background-color: #616883; */


		background-color: #171b32;




		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;			/* www.w3schools.com The z-index property specifies the stack order of an element.
						* An element with greater stack order is always in front of an element with a lower stack order. */
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */

.nav-links {
	/* color: white; */

	color: #171b32;
	font-family: 'Lora', serif;



	text-decoration: none;
	padding: 0.5rem 1rem;
}
.nav-links:hover {
	/* background-color: #6d76f7; */
	/* background-color: #616883; */

	background-color: #f1f1f1;

	border-radius: 8px;
	transition: all 0.2s ease-out;


/* Another for template: #6A7CE6 */
/* Another for template: #f1f1f1 */



	/* opacity: 0.25;			*/

}


/* css-tricks.com Every element on a web page is a rectangular box. Default is 'inline', whereas  'none' hides it. */
/*
* .nav-links-mobile {
* 	display: none;
* }
*/


@media screen and (max-width: 960px) {		
	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 85%;
		display: table;

		/* margin-bottom: 0.5rem; */


		color: #ffffff;

/* Mobile has only down state, not hover. Need to find it out. */


		justify-content: center;


	}
	.nav-links:hover {
		/* background-color: #7577fa; */

		background-color: #616883;




		/* background-color: #171b32; */

/* CORRECT THE STRIPS AROUND !!! PUT LOGO CENTERED WITH SIDE MENU AS WELL.

		/* color: #616883; */
		color: #ffffff;

		border-radius: 8px;


	}

/* www.w3schools.com  The text-decoration property adds an underline, overline, line-through, or a combination of lines to selected text. */
/*
*	.nav-links-mobile {
*		display: block;
*		text-align: center;
*		padding: 1.5rem;
*		margin: 2rem auto;
*		border-radius: 4px;
*		width: 80%;
*		background: #4ad9e4;
*		text-decoration: none;	
*		color: #fff;
*		font-size: 1.5rem;
*	}
*	.nav-links-mobile:hover{
*		background: #fff;
*		color: #6568F4;
*		transition: 250ms;
*	}
*/

	/* There may be a better method but for now simply targetting the component saying display none. */
	/* Button { display: none; } */
}

