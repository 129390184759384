* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lora', serif;
}

.grid {
	max-width: 60rem;
	margin: 5em;
	display: grid;
	gap: 10em;
	grid-template-columns: repeat(2,1ft);
}

.bio {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lora', serif;
	text-align: left;
	line-height: 2;
}

.photo {
	align-content: center;
}

@media screen and (max-width: 960px) {
	.grid {
		grid-template-columns: 1fr;
	}

	.clear_Contact {
		min-height: 25vh;
		background-color: white;
	}

}



/*
.form-container {
	position: relative;
	left: 20%;
	width: 60%;
	height: 100vh;
	background-color: $ffffff;
}
.contact-form {
	position: absolute;
	top: 10%;
	left: 10%;
	width: 80%;
	height: 600px;
}
*/

input, textarea {
	width: 100%;
	margin-top: 2rem;
	border: none;
	border-bottom: 1px solid black;
}

.submit {
	border: 1px solid black;
	padding: 1rem;
	text-align: center;
	background-color: #ffffff;
	cursor: pointer;

	height: 5vh;

	font-size: 15px;
	color: black;

}

.submit:hover {
	opacity: 0.6;
}

