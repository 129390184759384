
.grid {
	max-width: 60rem;
	margin: 5em;
	display: grid;
	gap: 5em;
	grid-template-columns: repeat(2,1fr);



}

.bio {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lora', serif;
	text-align: left;
	line-height: 2;



}

.photo {
	align-content: center;
	font-family: 'Lora', serif;



}

@media screen and (max-width: 960px) {
	.grid {
		grid-template-columns: 1fr;
	}

	.clear {
		min-height: 70vh;
		background-color: white;
	}




}



